import React, { createRef, useState, useEffect } from "react";
import BackButton from "../controls/BackButton";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import stepPages from "../helpers/steps";
import validator from "validator";
import ErrorContainer from "../controls/ErrorContainer";
import axios from "axios";
import uris from "../helpers/uris";

const ForgotUsername = props => {
const [email, setEmail] = useState(null);
let emailInput = createRef();
    
const [errorDescription, setErrorDescription] = useState(null);

const isValidEmail = email => {
    if(!email){
        return false;
    }
    return validator.isEmail(email);
};

const submit = (event) => {
    event.preventDefault();
    const inputText = emailInput.current.value;

    if (!isValidEmail(inputText)) {
        setErrorDescription("Please enter a valid email");
        return;
    }
    sendEmail(inputText);
};

const sendEmail = (emailAddress) => {
    const defaultError = "An error occurred. Could not send out a username reminder email.";
        
    var form = new FormData();
    form.append("email", emailAddress);
    form.append("clientName", props.settings.clientName);
    axios({
        method: "post",
        url: uris.sendusernamereminder,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then((res) => {
            if (res.status === 200) {
                setErrorDescription("");
                document.getElementById("fusForm").reset();
                props.setForgotUsernameReminderEmail(emailAddress);
                props.goToStep(stepPages.ForgotUsernameReminderSent);
            }
        })
        .catch((error) => {
            if (error.response && error.response.status > 200) {
                if (error.response.data) {
                    setErrorDescription(error.response.data);
                    return;
                }
            }
            setErrorDescription(defaultError);
        });
};

useEffect(() => {
    if(props.isActive){
        setErrorDescription(null);
        emailInput.current.focus();
    }
}, [props.isActive]);

return (
    <form id={"fusForm"} onSubmit={e => submit(e)}>
        <div className="modal-content" id={"divForgotUsername"}>
            <div>
                <BackButton myId={"fuBackButton"} goToStep={props.goToStep} step={stepPages.UsernameEntry} />
            </div>
            <div>
                <LogoHeader clientName={props.settings.clientName} />
            </div>
            <div>
                <p className="bold">Forgot Your Username?</p>
            </div>
            <div>
                <p>
                    We can send you a reminder to your email address or see
                    your Clinic Manager for assistance.
                </p>
                <ErrorContainer errorDescription={errorDescription} />
                <div>
                    <div className="stackLabelContent">
                        <label htmlFor={"funEmail"}>Email Address</label>
                        <input id={"funEmail"} type="text" ref={emailInput} />
                    </div>
                </div>
                <div>
                    <button id={"fuButtonSubmit"} type="submit" className="button full-width" onClick={submit}>
                        Submit
                    </button>
                </div>
            </div>
            <div>
                <LogoFooter />
            </div>
        </div>
    </form>);
};

export default ForgotUsername;
