import React from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import stepPages from '../helpers/steps';

const ForgotUsernameReminderSent = props => {
    return (
        <div className="modal-content">
            <div>
            <LogoHeader clientName={props.settings.clientName} />
            </div>
            <div>
                <p>A username reminder has been sent to</p>
                <p id={"fursParaEmail"} className="bold">{props.forgotUsernameReminderEmail}</p>
            </div>
            <div>
                <button id={"fursButtonReturnToLogin"} type="button" className="button full-width" onClick={() => props.goToStep(stepPages.UsernameEntry)}>
                    Return To Login
                </button>
            </div>
            <div>
                <LogoFooter />
            </div>
        </div>
    );
};

export default ForgotUsernameReminderSent;
