import React from 'react';
import LogoHeader from './LogoHeader';
import UsernameControl from './UsernameControl';

const LogoHeaderAndUsername = ( props ) => {
    return (    
    <div>
        <LogoHeader clientName={props.clientName} />
        <UsernameControl username={props.username}/>
    </div>);
};

export default LogoHeaderAndUsername;