import React, { createRef, useEffect, useState } from "react";
import uris from "../helpers/uris";
import ErrorContainer from "../controls/ErrorContainer";
import LogoHeaderAndUsername from "../components/LogoHeaderAndUsername";
import LogoFooter from "./LogoFooter";
import axios from "axios";
import stepPages from "../helpers/steps";

const SelectClinic = (props) => {

    // THIS VIEW IS NOT IN USE AND REMOVE ALL REFERENCES TO LAND HERE.
    let username = props.username;
    let select = createRef();

    if (props.isActive && !username) {
        props.goToStep(stepPages.UsernameEntry);
    }

    const loadingMessage = "Loading...";
    const [clinic, setClinic] = useState();
    const [loading, setLoading] = useState(true);
    const [errorDescription, setErrorDescription] = useState("");
    const [clinics, setClinics] = useState([
        { label: loadingMessage, value: "" },
    ]);

    const defaultError =
        "Unexpected error occurred while retrieving your clinics.";

    useEffect(() => {
        async function populateClinics() {
            setLoading(true); // we are loading
            var form = new FormData();
            form.append("username", username);
            axios({
                method: "post",
                url: uris.userclinics,
                data: form,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setErrorDescription("");
                        setClinics(
                            res.data.map(({ clinicName, siteId }) => ({
                                label: `${clinicName} (${siteId})`,
                                value: siteId,
                            }))
                        );
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status > 299) {
                        if (error.response.data) {
                            setErrorDescription(error.response.data);
                            setLoading(false);
                            return;
                        }
                    }
                    setErrorDescription(defaultError);
                });
        }
        if (props.isActive && username.length > 0) {
            populateClinics();
        }
    }, [props]);

    const clinicSelected = (e) => {
        setClinic(e.target.value);
    };

    const goToEScreen = () => {
        if (props.isActive && clinic) {
            let landingPage = new URL();
            landingPage.searchParams.append("clinic", clinic);
            window.location.href = landingPage;
            return;
        }
    };

    return (
        <form id={"csForm"}>
            <div className="modal-content">

                <LogoHeaderAndUsername username={username} />
                <ErrorContainer errorDescription={errorDescription} />
                <div>
                    <select id={"csSelect"} disabled={loading} ref={select} required onChange={clinicSelected} >
                        <option key={""} value={""}>
                            Select Clinic
                        </option>
                        {clinics.map(({ label, value }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <button id={"csNextButton"} type="button" className="button full-width" disabled={!clinic} onClick={goToEScreen} >
                        Next
                    </button>
                </div>
                <div>
                    <LogoFooter />
                </div>

            </div>
        </form>
    );
};

export default SelectClinic;
