import React, { createRef, useState, useEffect } from "react";
import axios from "axios";
import FormData from "form-data";
import BackButton from "../controls/BackButton";
import LogoHeaderAndUsername from "./LogoHeaderAndUsername";
import LogoFooter from "./LogoFooter";
import stepPages from "../helpers/steps";
import validator from "validator";
import ErrorContainer from "../controls/ErrorContainer";
import uris from "../helpers/uris";

const ResetPasswordViaEmail = (props) => {
    const [errorDescription, setErrorDescription] = useState(null);
    let returnUrlValue = props.returnUrl;
    let inputText = createRef();
    const [hasResetPasswordEmailBeenSent, setHasResetPasswordEmailBeenSent] = useState(false);

    const submit = (event) => {
        event.preventDefault();
        const emailInput = inputText.current.value;
        const usernameInput = props.username;
        if (validator.isEmail(emailInput)) {
            setHasResetPasswordEmailBeenSent(true);
            sendResetPasswordEmail(usernameInput, emailInput, returnUrlValue);
        } else {
            setHasResetPasswordEmailBeenSent(false);
            setErrorDescription("Please enter a valid email");
        }
    };

    const sendResetPasswordEmail = (username,email, returnUrlValue) => {
        var form = new FormData();
        form.append('username', username);
        form.append('email', email);
        form.append('returnUrl', returnUrlValue);
        axios({
            method: "post",
            url: uris.sendresetpasswordrequest,
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                props.setForgotPasswordReminderEmail(email);
                props.goToStep(stepPages.ResetPasswordViaEmailSent);
            })
            .catch((error) => {
                setHasResetPasswordEmailBeenSent(false);
                setErrorDescription("Not able to send password request");
                if (error.response && error.response.status > 200) {
                    if (error.response.data) {
                        setErrorDescription(error.response.data);
                        return;
                    }
                }
                return;
            });
    };

    useEffect(()=>{
        if(props.isActive){
            setErrorDescription(null);
            inputText.current.value = null;
            inputText.current.focus();
            setHasResetPasswordEmailBeenSent(false);
        }
    }, [props.isActive])

    return (
        <form id={"fpwForm"} onSubmit={e => submit(e)}>
            <div className="modal-content">

                <div>
                    <BackButton myId={"rpveBackButton"} goToStep={props.goToStep} step={stepPages.ForgotPassword}/>
                </div>
                <div>
                    <LogoHeaderAndUsername username={props.username} clientName={props.settings.clientName} />
                </div>
                <ErrorContainer errorDescription={errorDescription} />
                <div>
                    <p className="bold">Password Reset</p>
                    <p>Enter your email address to receive a reset link.</p>
                </div>
                <div className="stackLabel">
                    <div>
                        <div className="stackLabelContent">
                            <div>
                                <label htmlFor={"funEmail"}>Email Address</label>
                                </div>
                            <input id={"rpveEmail"} type="text" ref={inputText} />
                        </div>
                    </div>
                </div>
                <div>
                    <button type="submit" className="button full-width" disabled={hasResetPasswordEmailBeenSent} onClick={submit}>
                        Submit
                    </button>
                </div>
                <div>
                    <LogoFooter />
                </div>

            </div>
        </form>
    );
};

export default ResetPasswordViaEmail;
