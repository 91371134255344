import React,{ useEffect, useState } from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import uris from "../helpers/uris";
import axios from "axios";
import ErrorContainer from "../controls/ErrorContainer";

const ResetPasswordHasBeenReset = (props) => {
    const [errorDescription, setErrorDescription] = useState(null);
    const [clientName, setClientName] = useState(props.settings.clientName);
    const returnToClient = () => {
        axios({
                method: "get",
                url: uris.redirecttoclientapplication,
                params: {
                    clientId : clientName,
                },
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                var redirectUrl  = res.data.url;
                if(redirectUrl){
                    window.location.href = redirectUrl;
                }
            })
            .catch((error) => {
                setErrorDescription("Unknown Error Occured");
            })
    };
    useEffect(() => {
        if (props.isActive && props.clientName) {
            setClientName(props.clientName);
        }
    }, [props.isActive, props.clientName]);

    return (
        <div className="modal-content">
            <div>
                <LogoHeader clientName={clientName} />
            </div>
            <div>
                <div>
                    <p className="bold">Password has been reset. </p>
                </div>
            </div>
            <div>
				<div>
					<ErrorContainer errorDescription={errorDescription} />
                </div>
            </div>
            <div>
                <button id={"rphbrReturnToLogin"} type="button" className="button full-width" onClick={returnToClient}>
                    Return to Login
                </button>
            </div>
            <div>
                <LogoFooter />
            </div>
        </div>
    );
};

export default ResetPasswordHasBeenReset;
