import React from "react";
import StepWizard from "react-step-wizard";
import ForgotPassword from "./ForgotPassword";
import ForgotUsername from "./ForgotUsername";
import ForgotUsernameReminderSent from "./ForgotUsernameReminderSent";
import ForgotUsernameRetrieval from "./ForgotUsernameRetrieval";
import PasswordEntry from "./PasswordEntry";
import ResetPasswordAndConfirm from "./ResetPasswordAndConfirm";
import ResetPasswordViaEmail from "./ResetPasswordViaEmail";
import ResetPasswordViaEmailSent from "./ResetPasswordViaEmailSent";
import ResetPasswordViaSecretQuestion from "./ResetPasswordViaSecretQuestion";
import UsernameEntry from "./UsernameEntry";
import AccountLocked from "./AccountLocked";
import ResetPasswordHasBeenReset from "./ResetPasswordHasBeenReset";
import SelectClinic from "./SelectClinic";
import searchParameters from "../helpers/searchParameters";
import getSettingValue from "../helpers/getSettingValue";
import IncorrectAccountConfiguration from './IncorrectAccountConfiguration';

class LoginWizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            userEmail:"",
            returnUrl: "",
            forgotUsernameReminderEmail: "",
            forgotPasswordReminderEmail: "",
            answer1: "",
            answer2: "",
            answer3: "",
            passwordEntryShowError: false,
            resetPasswordReason: "",
            resetPasswordToken:"",
            clientName: "",
            settings : null,
        };
    }

    setSettings = value =>{
        this.setState({settings: value});
    }

    clearStatesProp = () => {
        this.setUsername(null);
        this.setUserEmail(null);
        this.setAnswer1(null);
        this.setAnswer2(null);
        this.setAnswer3(null);
    }

    setForgotUsernameReminderEmail = value => {
        this.setState({ forgotUsernameReminderEmail: value });
    };

    setForgotPasswordReminderEmail = value => {
        this.setState({ forgotPasswordReminderEmail: value });
    };

    setUsername = value => {
        this.setState({ username: value });
    };

    setUserEmail = value => {
        this.setState({ userEmail: value });
    }

    setReturnUrl = value => {
        this.setState({
            ...this.state,
            returnUrl: value
        });
    };
    
    setInitialQuestion = value =>{
        this.setState({setInitialQuestion: value});
    }
    
    setAnswer1 = value => {
        this.setState({ answer1: value });
    };

    setAnswer2 = value => {
        this.setState({ answer2: value });
    };

    setAnswer3 = value => {
        this.setState({ answer3: value });
    };

    setClientName = value => {
        this.setState({ clientName: value});
    }

    setResetPasswordReason = value =>{
        this.setState({ resetPasswordReason: value });
    }
    setResetPasswordToken = value =>{
        this.setState({resetPasswordToken:value});
    }

    componentDidMount(){
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get(searchParameters.returnUrlSearchParameter);
        if (returnUrl) {
            this.setReturnUrl(returnUrl);
        }
        if(this.props.settings) {
            this.setSettings(this.props.settings);
            if(this.props.settings.clientName){
                this.setClientName(this.props.settings.clientName);
            }
        }        
    }

    render() {

        let returnUrl = new URLSearchParams(window.location.search).get(searchParameters.returnUrlSearchParameter);
        if (returnUrl) {
            let url = new URL(returnUrl);
            var timeout_url = new URLSearchParams(url.search).get('timeout_url');
        }

        function timeoutRedirect() {
            window.location.href = timeout_url;
        }

        if (timeout_url != null && timeout_url != "") {
            setTimeout(timeoutRedirect, 14 * 60000);          
        }

        return (
            <div className="modal">
                <StepWizard isHashEnabled={true} >
                    <UsernameEntry
                        hashKey={"usernameentry"}
                        updateUser={this.setUsername}
                        updateUserEmail = {this.setUserEmail}
                        username={this.state.username}
                        returnUrl={this.state.returnUrl}
                        settings={this.props.settings}
                        clearStatesProp = {this.clearStatesProp}
                    />
                    <ForgotUsername
                        hashKey={"forgotusername"}
                        settings={this.props.settings}
                        username={this.state.username}
                        setForgotUsernameReminderEmail={
                            this.setForgotUsernameReminderEmail
                        }
                    />
                    <ForgotUsernameReminderSent
                        hashKey={"forgotusernameremindersent"}
                        settings={this.props.settings}
                        username={this.state.username}
                        forgotUsernameReminderEmail={
                            this.state.forgotUsernameReminderEmail
                        }
                    />
                    <PasswordEntry
                        hashKey={"passwordentry"}
                        settings={this.props.settings}
                        username={this.state.username}
                        returnUrl={this.state.returnUrl}
                        userNameUpdater
                        passwordEntryShowError={
                            this.state.passwordEntryShowError
                        }
                        setResetPasswordReason={this.setResetPasswordReason}
                        setResetPasswordToken={this.setResetPasswordToken}
                    />
                    <ForgotPassword
                        hashKey={"forgotpassword"}
                        settings={this.props.settings}
                        username={this.state.username}
                        userEmail={this.state.userEmail}
                        forgotUsernameReminderEmail={
                            this.state.forgotUsernameReminderEmail
                        }
                        returnUrl={this.state.returnUrl}
                        setInitialQuestion={this.setInitialQuestion}
                        setAnswer1={this.setAnswer1}
                        setAnswer2={this.setAnswer2}
                        setAnswer3={this.setAnswer3}
                    />
                    <ResetPasswordViaSecretQuestion
                        hashKey={"question1"}
                        settings={this.props.settings}
                        num={1}
                        username={this.state.username}
                        answer1={this.state.answer1}
                        answer2={this.state.answer2}
                        answer3={this.state.answer3}
                        setAnswer1={this.setAnswer1}
                        setAnswer2={this.setAnswer2}
                        setAnswer3={this.setAnswer3}
                    />
                    <ResetPasswordViaSecretQuestion
                        hashKey={"question2"}
                        settings={this.props.settings}
                        num={2}
                        username={this.state.username}
                        answer1={this.state.answer1}
                        answer2={this.state.answer2}
                        answer3={this.state.answer3}
                        setAnswer1={this.setAnswer1}
                        setAnswer2={this.setAnswer2}
                        setAnswer3={this.setAnswer3}
                    />
                    <ResetPasswordViaSecretQuestion
                        hashKey={"question3"}
                        settings={this.props.settings}
                        num={3}
                        username={this.state.username}
                        answer1={this.state.answer1}
                        answer2={this.state.answer2}
                        answer3={this.state.answer3}
                        setAnswer1={this.setAnswer1}
                        setAnswer2={this.setAnswer2}
                        setAnswer3={this.setAnswer3}
                        setResetPasswordReason={this.setResetPasswordReason}
                        setResetPasswordToken={this.setResetPasswordToken}
                    />
                    <ResetPasswordViaEmail
                        hashKey={"resetpasswordviaemail"}
                        settings={this.props.settings}
                        username={this.state.username}
                        forgotPasswordReminderEmail={
                            this.state.forgotPasswordReminderEmail
                        }
                        setForgotPasswordReminderEmail={
                            this.setForgotPasswordReminderEmail
                        }
                        returnUrl={this.state.returnUrl}
                    />
                    <ResetPasswordViaEmailSent
                        hashKey={"resetpasswordviaemailsent"}
                        settings={this.props.settings}
                        forgotPasswordReminderEmail={
                            this.state.forgotPasswordReminderEmail
                        }
                    />
                    <ResetPasswordAndConfirm
                        hashKey={"resetpassword"}
                        settings={this.props.settings}
                        username={this.state.username}
                        updateUser={this.setUsername}
                        updateClientName={this.setClientName}
                        setResetPasswordReason={this.setResetPasswordReason}
                        setResetPasswordToken={this.setResetPasswordToken}
                        resetPasswordReason = {this.state.resetPasswordReason}
                        resetPasswordToken = {this.state.resetPasswordToken}
                    />
                    <AccountLocked 
                        hashKey={"accountlocked"}
                        settings={this.props.settings}
                     />
                    <ResetPasswordHasBeenReset  
                        hashKey={"passwordwasreset"} 
                        returnUrl={this.state.returnUrl}
                        settings={this.props.settings}
                        clientName={this.state.clientName}
                    />
                    <SelectClinic 
                        username={ this.state.username }
                        settings={this.props.settings} 
                        hashKey={"selectclinic"}
                    />
                    <ForgotUsernameRetrieval
                        hashKey={"forgotusernameretrieval"}
                        settings={this.props.settings}
                        username={this.state.username}
                    />
                    <IncorrectAccountConfiguration
                        hashKey={"incorrectaccountconfiguration"}
                        settings={this.props.settings}
                        username={this.state.username}
                    />
                </StepWizard>
            </div>
        );
    }
}

export default LoginWizard;
