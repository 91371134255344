import React, { useEffect, useState } from "react";
import stepPages from "../helpers/steps";
import BackButton from "../controls/BackButton";
import ResetQuestionsImage from "../controls/ResetQuestionsImage";
import EmailImage from "../controls/EmailImage";
import LogoHeaderAndUsername from "./LogoHeaderAndUsername";
import LogoFooter from "./LogoFooter";

const ForgotPassword = props => {
    let returnUrlValue = props.returnUrl;
    const [resetViaEmailEnabled, setResetViaEmailEnabled] = useState(false);
    const [challengeQuestionSupported, setIsChallengeQuestionRecovery] = useState(false);
    const [lastOptionNumber, setLastOptionNumber] = useState(2);
    const gotoResetViaSecretQuestions = () => {
        props.goToStep(stepPages.ResetPasswordViaSecretQuestion1);
    };

    const gotoResetViaEmail = () => {
        props.goToStep(stepPages.ResetPasswordViaEmail);
    };

    const getOptionNumber = () => {
        if(challengeQuestionSupported && resetViaEmailEnabled){
            return 2;
        }
        return 1;
    }

    useEffect(() => {
        if(props.isActive){
            props.setAnswer1("");
            props.setAnswer2("");
            props.setAnswer3("");
        }
        if(props.userEmail && props.settings.featuresSupported.ForgotPasswordEmailRecovery){
            setResetViaEmailEnabled(true);
        }
        if (props.settings.featuresSupported.ForgotPasswordChallengeQuestionsRecovery){
            setIsChallengeQuestionRecovery(true);
        }
        setLastOptionNumber(getOptionNumber);
        
    }, [props.isActive, props.userEmail, props.settings])

    return (
        <div className="modal-content">
            <div>
                <BackButton myId={"fpBackButton"} goToStep={props.goToStep} step={stepPages.PasswordEntry}/>
            </div>
            <div>
                <LogoHeaderAndUsername username={props.username} clientName={props.settings.clientName} />
            </div>
            <div>
                <p className="bold">Choose password reset method</p>
            </div>
            { challengeQuestionSupported && 
                <div className="forgotQuestionOption">
                    <div>
                        <ResetQuestionsImage />
                        <p className="bold">Option 1 - Secret Questions</p>
                    </div>
                    <div>
                        <button id={"fpButtonResetViaSecretQuestions"} style={{ margin: "0px" }} type="button" className="button full-width" onClick={gotoResetViaSecretQuestions}>
                            Reset via Secret Questions
                        </button>
                    </div>
                </div>
            }
            <div className="forgotQuestionOption" style={{ display: resetViaEmailEnabled ? "block": "none" }}>
                <div>
                    <EmailImage />
                    <p className="bold">Option {lastOptionNumber} - Email</p>
                </div>
                <div>
                    <button id={"fpButtonResetViaEmail"} style={{ marginLeft: "0px" }} type="button" className="button full-width" onClick={gotoResetViaEmail}>
                        Reset via Email
                    </button>
                </div>
            </div>
            <div>
                <LogoFooter />
            </div>
        </div>
    );
};

export default ForgotPassword;
