import axios from "axios";
import uris from "./uris";

const getSettings = (clientId, setFunc, setErrorFunc, defaultErrorMsg) => {

    axios({
        method: "get",
        url: uris.clientsettings,
        params: {
            clientId: clientId,
        },
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then((res) => {
            var settings = res.data;
            setFunc(settings);
        })
        .catch((error) => {
            if (!setErrorFunc) {
                console.log(error);
                return;
            }
            if (error.response) {
                setErrorFunc(error.response.data);
                return;
            }

            if (defaultErrorMsg) {
                setErrorFunc(defaultErrorMsg);    
            }
        });
};

export default getSettings;