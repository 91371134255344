import React from 'react';
import email from '../forgot-password-email.svg';

const EmailImage = () => {
    return (    
        <div className="questionIcon">
            <img src={email} alt={"Email"} />
        </div>
    );
};

export default EmailImage;