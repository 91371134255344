import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ErrorContainer = props => {
    return (
        props.errorDescription && (
            <div className="errorContainer">
                <div className="inlineFlex inlineFlex--align-center  abt-color--red">
                    <FontAwesomeIcon icon={faTimes} className="fa-sm" />
                    <div>
                        <span>{props.errorDescription}</span>
                    </div>
                </div>
            </div>
        )
    );
};

export default ErrorContainer;
