import React from 'react';
import escreen from '../escreen.png';
import Heading from '../controls/Heading';

const LogoHeader = (props) => {
    return (    
        <div>
            <div className="modal-child">
                <img alt={"Logo"} src={escreen} className="logo" />
                <Heading messageStr="Login to" clientStr={props.clientName} />
            </div>
        </div>
    );
};

export default LogoHeader;