import React from 'react';
import abbottLogo from '../Abbott_logo.png';

const LogoFooter = (props) => {
    return (
        <div>
            <div className="footer-logo-container">
                <img alt={"Abbott Logo"} src={abbottLogo} />
            </div>
        </div>
    );
};

export default LogoFooter;