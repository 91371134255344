import React, { createRef, useState, useEffect } from "react";
import axios from "axios";
import LogoHeaderAndUsername from "./LogoHeaderAndUsername";
import LogoFooter from "./LogoFooter";

import BackButton from "../controls/BackButton";
import stepPages from "../helpers/steps";
import uris from "../helpers/uris";
import searchParameters from "../helpers/searchParameters";
import QuestionsImage from "../controls/QuestionsImage";
import ErrorContainer from "../controls/ErrorContainer";
import PasswordMask from "react-password-mask";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ResetPasswordViaSecretQuestion = (props) => {
    let readyToLoad = true;
    const initialQuestionValue = "Loading...";
    const num = props.num;
    const [current, setCurrent] = useState("");
    const [question, setQuestion] = useState(initialQuestionValue);
    const [errorDescription, setErrorDescription] = useState(null);
    const [canAnswer, setCanAnswer] = useState(false);
    const [isChallengeQuestionSupported, setIsChallengeQuestionSupported] = useState(true);

    const answer = createRef();
    const submit = createRef();
    const handleChange = (e) => {
        setCurrent(e.target.value);
    };

    const setCurrentAnswer = (answer) => {
        switch (props.num) {
            case 1:
                props.setAnswer1(answer);
                break;
            case 2:
                props.setAnswer2(answer);
                break;
            case 3:
                props.setAnswer3(answer);
                break;
            default:
                break;
        }
    };

    const goToReset =()=>{
        props.goToStep(stepPages.ResetPasswordAndConfirm);
    }
    const goToUsernameEntry =()=>{
        props.goToStep(stepPages.UsernameEntry);
    }

    useEffect(() => {
        setErrorDescription("");
        if (props.isActive && props.username.length === 0) {
            setErrorDescription(
                "You must enter a username to answer challenge questions"
            );
            props.goToStep(stepPages.UsernameEntry);
            return;
        }

        if(props.isActive && !props.settings.featuresSupported.ForgotPasswordChallengeQuestionsRecovery){
            setIsChallengeQuestionSupported(false);
            goToUsernameEntry();
        };

        answer.current.value = "";
        setCurrent("");
        setQuestion(initialQuestionValue);

        if (
            props.isActive &&
            props.username.length > 0 &&
            readyToLoad&& 
            props.settings.featuresSupported.ForgotPasswordChallengeQuestionsRecovery
        ) {
            var form = new FormData();
            form.append("userName", props.username);
            form.append("clientName", props.settings.clientName);
            form.append("num", props.num);
            form.append("answer1", num === 1 ? current : props.answer1);
            form.append("answer2", num === 2 ? current : props.answer2);
            form.append("answer3", num === 3 ? current : props.answer3);
            axios({
                method: "post",
                url: uris.challengeQuestions,
                data: form,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((res) => {
                    setQuestion(res.data.question);
                    setCanAnswer(true);
                    readyToLoad = false;
                    document.getElementById("rpvsqAnswer" + num).focus();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 401:             
                        case 403:
                            props.goToStep(stepPages.AccountLocked);
                            break;
                        default:
                            setErrorDescription("Bad Request");
                            setQuestion(error.response.data);
                            setCanAnswer(false);
                            readyToLoad = false;
                            break;
                    }
                });
        }
    }, [props.username, props.isActive]);

    const answerQuestion = () => {
        if (props.isActive && props.username.length === 0) {
            setErrorDescription(
                "Answering the challenge question is not permitted"
            );
        }
        setErrorDescription("");
        setCanAnswer(false);
        if (props.isActive && props.username.length > 0 && current != null) {
            const a = current;
            setCurrentAnswer(a);

            if (a.trim() === "") {
                setErrorDescription("Answer is Required");
                setCanAnswer(true);
                return;
            }
           
            var form = new FormData();
            form.append("userName", props.username);
            form.append("num", props.num);
            form.append("clientName", props.settings.clientName);
            form.append("answer1", num === 1 ? a : props.answer1);
            form.append("answer2", num === 2 ? a : props.answer2);
            form.append("answer3", num === 3 ? a : props.answer3);
            axios({
                method: "post",
                url: uris.answerChallenges,
                data: form,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setErrorDescription("");
                        setCanAnswer(true);
                        switch (num) {
                            case 1:
                                props.goToStep(
                                    stepPages.ResetPasswordViaSecretQuestion2
                                );
                                break;
                            case 2:
                                props.goToStep(
                                    stepPages.ResetPasswordViaSecretQuestion3
                                );
                                break;
                            case 3:
                                props.setResetPasswordToken(res.data.changeRequestId);
                                goToReset();
                                //var guid = res.data.changeRequestId;
                                //window.location.href = `?${searchParameters.guidSearchParameter}=${guid}#resetpassword`;
                            default:
                                break;
                        }
                    } else {
                        setErrorDescription(
                            "Unexpected error occurred. Please try again."
                        );
                    }
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 401:
                        case 403:
                            props.goToStep(stepPages.AccountLocked);
                            break;
                        default:
                            setErrorDescription("That answer was not correct. Please try again.");
                            setCanAnswer(true);
                            break;
                    }
                });
        }
    };

    return (
        <form id={"rpvsqForm"}>
            <div className="modal-content">
                <div>
                    <BackButton
                        myId={"rpvsqBackButton"}
                        step={stepPages.ForgotPassword}
                        goToStep={props.goToStep}
                    />
                </div>
                <div>
                    <LogoHeaderAndUsername username={props.username} clientName={props.settings.clientName} />
                </div>
                <div>
                    <div>
                        <p className="bold">Password Reset</p>
                        <p>
                            Answer your secret questions to reset your password.
                        </p>
                    </div>
                </div>
                <div>
                    <div className="modal-child">
                        <QuestionsImage number={num} />
                    </div>
                </div>
                <ErrorContainer errorDescription={errorDescription} />
                <div className="stackLabel">
                    <div>
                        <div className="stackLabelContent">
                            <label
                                className="bold"
                                id={`rpvsqLabelAnswer${num}`}
                                htmlFor={`answer1${num}`}
                            >
                                {question}
                            </label>
                            <PasswordMask
                                id={`rpvsqAnswer${num}`}
                                type="password"
                                className="form-control password-mask"
                                inputClassName = {"questions"}
                                ref={ answer }
                                disabled={!canAnswer}
                                value={ current }
                                onChange={ handleChange }          
                                showButtonContent = { <FontAwesomeIcon icon={ faEye } color={ "#888B8D" } /> }
                                hideButtonContent = { <FontAwesomeIcon icon={ faEyeSlash } color={ "#888B8D" } /> }
                                buttonStyles={{
                                    backgroundColor: 'white',
                                    width: '1em',
                                    height: '1em',
                                    display: !canAnswer ? 'none' : ''
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        id={`rpvsqSubmit${num}`}
                        type="button"
                        className="button full-width"
                        onClick={answerQuestion}
                        ref={submit}
                        disabled={!canAnswer}
                    >
                        Submit
                    </button>
                </div>
                <div>
                    <LogoFooter />
				</div>
            </div>
        </form>
    );
};

export default ResetPasswordViaSecretQuestion;
