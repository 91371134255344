import React, { createRef, useState, useEffect } from "react";
import LogoHeaderAndUsername from "./LogoHeaderAndUsername";
import LogoFooter from "./LogoFooter";
import ErrorContainer from "../controls/ErrorContainer";
import stepPages from "../helpers/steps";
import uris from "../helpers/uris";
import searchParameters from "../helpers/searchParameters";
import axios from "axios";

const ResetPasswordAndConfirm = (props) => {
    let passwordrescomInput = createRef();
    let defaultError =
    "The password could not be changed. Please try again.";

    const defaultResetPasswordText = "Type your new password below";
    const [resetReasonText, setResetReasonText] = useState(defaultResetPasswordText);
    const urlParams = new URLSearchParams(window.location.search);
    const guid = urlParams.get(searchParameters.guidSearchParameter);

    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [errorDescription, setErrorDescription] = useState(null);
    const [clientName, setClientName] = useState("");

    if (props.isActive && !guid && !props.resetPasswordToken) {
        props.goToStep(stepPages.UsernameEntry);
    }

    const handlePasswordChange = (evt) => {
        setErrorDescription("");
        setPassword(evt.target.value);
    };

    const handlePasswordConfirmChange = (evt) => {
        setErrorDescription("");
        setConfirmPassword(evt.target.value);
    };

    const updateUserName = newname => {
        props.updateUser(newname);
    };

    const updateToken = value =>{
        props.setResetPasswordToken(null);
    }

    const updateClientName = value =>{
        setClientName(value);
        props.updateClientName(value);
    }

    const reasonText = () =>{
        if(resetReasonText){
            var reasonText = resetReasonText;
            return reasonText;
        }
        else{
            return defaultResetPasswordText;
        }
        
    }

    const getResetRequest = () => {
        axios({
            method: "get",
            url: uris.resetpassword,
            params: {
                requestGuid: guid
            },
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                if (res.status === 200) {
                    setErrorDescription("");
                    //updateUserName(res.data.userName);
                    setUsername(res.data.userName);
                    setResetReasonText(res.data.resetPasswordReason);
                    updateClientName(res.data.clientName);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    if (error.response.data) {
                        setErrorDescription(error.response.data);
                        return;
                    }
                }
                setErrorDescription(defaultError);
            });
    }

    const submit = () => {
        if (!password || !confirmPassword) {
            setErrorDescription("Please enter and confirm new password.");
            return;
        }
        if (password !== confirmPassword) {
            setErrorDescription("The passwords must match.");
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        var guid = urlParams.get(searchParameters.guidSearchParameter);
        if (!guid && props.resetPasswordToken){
            guid = props.resetPasswordToken;
        }
        var form = new FormData();
        form.append("guid", guid);
        form.append("password", password);
        form.append("confirmPassword", confirmPassword);
        axios({
            method: "post",
            url: uris.resetpassword,
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                if (res.status === 200) {
                    setErrorDescription("");
                    setResetReasonText(null);
                    props.goToStep(stepPages.ResetPasswordHasBeenReset);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    if (error.response.data) {
                        setErrorDescription(error.response.data);
                        return;
                    }
                }
                setErrorDescription(defaultError);
            });
    };
    useEffect(() => {
        if (props.isActive) {
            setErrorDescription(null);
            if (props.username && props.settings.clientName) {
                setUsername(props.username);
                updateClientName(props.settings.clientName);
                if(props.resetPasswordReason){
                    setResetReasonText(props.resetPasswordReason);
                }
            }
            else {
                getResetRequest();
            }
            passwordrescomInput.current.focus();
        }
    }, [props.isActive]);

    return (
        <form id={"rpacForm"}>
            <div className="modal-content">
                <LogoHeaderAndUsername username={userName} clientName={clientName} />
                <div>
                    <p className="bold">Password Reset</p>
                    <p>{reasonText()}</p>
                </div>
                <ErrorContainer errorDescription={errorDescription} />
                <div className="stackLabel">
                    <div>
                        <div className="stackLabelContent">
                            <div>
                            <label id={"rpwLabelPassword"} htmlFor={"rpwPassword"}>
                                Enter password
                            </label>
                                </div>
                            <input
                                type="password"
                                className="form-control"
                                id={"rpwPassword"}
                                onChange={handlePasswordChange}
                                ref={passwordrescomInput}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="stackLabel">
                    <div>
                        <div className="stackLabelContent">
                            <div>
                            <label
                                id={"rpwLabelConfirmPassword"}
                                htmlFor={"rpwConfirmPassword"}
                            >
                                Re-enter password
                            </label>
                                </div>
                            <input
                                id={"rpwConfirmPassword"}
                                type="password"
                                className="form-control"
                                onBlur={handlePasswordConfirmChange}
                                required
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <button id={"rpwButtonSubmit"} type="button" className="button full-width" onClick={submit}>
                        Submit
                    </button>
                </div>
                <div>
                    <LogoFooter />
                </div>

            </div>
        </form>
    );
};

export default ResetPasswordAndConfirm;