import React, { createRef, useState, useEffect } from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import axios from "axios";
import FormData from "form-data";
import stepPages from "../helpers/steps";
import uris from "../helpers/uris";
import ErrorContainer from "../controls/ErrorContainer";
import searchParameters from "../helpers/searchParameters";

const UsernameEntry = ( props ) => {
    let usernameInput = createRef(null);
    let returnUrlValue = props.returnUrl;
    var inFrame = false;

    const [errorDescription, setErrorDescription] = useState(null);
    const [isCertUser, setIsCertUser] = useState(false);
    const [isFimSite, setIsFimSite] = useState(false);
    const [isForgotUserNameSupported, setIsforgotUserNameSupported] = useState(true);
    const [clientName, setClientName] = useState(null);
    
    function checkforframe() {
        if (window.top.parent.frames['main'] != null) {
            inFrame = true;
        }

    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get(searchParameters.returnUrlSearchParameter);

        if(props.isActive){
            setErrorDescription(null);
            props.clearStatesProp();
            usernameInput.current.focus();
        }

        if(!props.settings.featuresSupported.ForgotUsername){
            setIsforgotUserNameSupported(false);
        }
        
        if (!returnUrl) {
            return;
        }

        const acrValues = new URLSearchParams(returnUrl).get("acr_values");
        if (!acrValues) {
            return;
        }

        const splitAcrValues = acrValues.split('|');
        const certUserValue = 
            splitAcrValues.map(x => { 
                    let y = x.split(':'); 
                    return { [y[0]]:y[1] };})
                .filter(x => 
                    x.certuser === "true").length > 0;
        setIsCertUser(certUserValue);

        const fimSiteValue = 
            splitAcrValues.map(x => { 
                    let y = x.split(':'); 
                    return { [y[0]]:y[1] };})
                .filter(x => 
                    x.fimsite === "true").length > 0;
        setIsFimSite(fimSiteValue);

        setClientName(props.settings.clientName);
    
    }, [props.isActive, props.settings]);

    const updateUserEmail=(email) =>{
        props.updateUserEmail(email);
    }

    const verifyUserName = (newValue) => {
        var form = new FormData();
        form.append('username', newValue);
        form.append('returnUrl', returnUrlValue);
        axios({
            method: "post",
            url: uris.verifyusername,
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                var challengeUrl = res.data.challengeUrl;
                if (challengeUrl) {
                    checkforframe();
                    if (inFrame) {
                        window.open(challengeUrl, "FimPopout");
                    }
                    else {
                        window.location.href = challengeUrl;
                    }
                }
                else{
                    if(res.data.userEmail){
                        updateUserEmail(res.data.userEmail);
                    }
                    props.goToStep(stepPages.PasswordEntry);
                }
            })
            .catch((error) => {
                setErrorDescription("Unknown Error Occured");
            })
    };

    const submit = ( event ) => {
        event.preventDefault();
        const inputText = usernameInput.current.value;
        updateUserName(inputText);
        if (inputText.trim() == "") {
            setErrorDescription("User ID Required");
            return;
        }
        verifyUserName(inputText);
    };

    const gotoForgotUsername = () => {
        props.goToStep(stepPages.ForgotUsername);
    };

    const updateUserName = newname => {
        props.updateUser(newname);
    };

    return (
        
        <form id= "userEntryForm" onSubmit={ e => submit(e) }>
			<div className="modal-content" id={"divUsernameEntry"}>

                <LogoHeader clientName={props.settings.clientName} />
			    <ErrorContainer errorDescription={errorDescription} />
        
                <div className="stackLabel">
                    <div className="stackLabelContent">
                        <label>Username</label>
						<input type="text" placeholder="Enter Your Username" className="username" className="form-control" defaultValue="" id={"ueUserName"} ref={usernameInput} />
                    </div>
				</div>
				<div>
					<button id={"ueBtnNext"} type="submit" className="button full-width" onClick={submit}>
						Next
					</button>
				</div>
                <div className={"centered"} >
                    <div 
                        id={"ueForgotUsernameLink"} 
                        className="smalllink" onClick={gotoForgotUsername}
                        style={{ display: !isForgotUserNameSupported || (isCertUser && isFimSite) ? "none" : "block" }}>
						Forgot Username?
					</div>
                </div>
                <div>
                    <LogoFooter />
                    <i className="abt-icon icon-Left abt-icon--x0 abt-color--white" />
				</div>
			</div>
		</form>
    );
};

export default UsernameEntry;
