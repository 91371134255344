import React from "react";

function Heading(props) {
    let message = ( props.messageStr ? props.messageStr.toUpperCase() : '' );
    let client = ( props.clientStr ? props.clientStr.toUpperCase() : '' );
    let newClient = "";
    

    if (client && client.startsWith("ESCREEN")) {
        newClient = client.replace('ESCREEN', 'eSCREEN');
    }
    
    return (
        <h4 className="modal-heading">
            {message} {(newClient !== "" ? newClient : client)}
        </h4>
    );
}

export default Heading