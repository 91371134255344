import React from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import stepPages from "../helpers/steps";

const ResetPasswordViaEmailSent = props => {
    return (
        <div className="modal-content">

            <div>
                <LogoHeader clientName={props.settings.clientName} />
            </div>
            <div>
                <p className="bold">Password Reset Email Sent</p>
            </div>
            <div>
                <p>An email has been sent to</p>
                <p className="bold" id={"rpvesParaEmail"}>{props.forgotPasswordReminderEmail}.</p>
            </div>
            <div>
                <p>
                    Follow the directions in the email to reset your password.
                </p>
            </div>
            <div>
                <button id={"rpvesButtonReturnToLogin"} type="button" className="button full-width" onClick={() => props.goToStep(stepPages.UsernameEntry)} >
                    Return To Login
                </button>
            </div>
            <div>
                <LogoFooter />
            </div>

        </div>
    );
};

export default ResetPasswordViaEmailSent;
