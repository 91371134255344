import React from "react";
import LoginWizard from "./LoginWizard";

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };

    }

    render() {
        return (
            <div className="login-container modal-dialog">
                <LoginWizard 
                    settings={this.props.settings} 
                />
            </div>
        );
    }
}

export default LoginContainer;
