import React, { useEffect, useState } from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import stepPages from "../helpers/steps";
import ErrorContainer from "../controls/ErrorContainer";

const AccountLocked = ( props ) => {
    const [errorDescription, setErrorDescription] = useState(null);

    useEffect(() => {

    },[props.isActive]);

    return (
        <div className="modal-content">
            <div>
                <div>
                    <LogoHeader clientName={props.settings.clientName} />
                </div>
            </div>
            <div>
                <p className="bold">Account Locked</p>
            </div>
            <ErrorContainer errorDescription={errorDescription} />
            <div>
                <div>
                    <p style={{whiteSpace:"pre-line"}}>{props.settings.accountLockedErrorMessage}</p>
                    <p>{props.settings.clientServicesPhoneNumber}</p>
                </div>
            </div>
            <div>
                <div>
                    <button
                        id={"alButtonReturnToLogin"}
                        type="button"
                        className="button full-width"
                        onClick={() =>
                            props.goToStep(stepPages.UsernameEntry)
                        }
                    >
                        Return To Login
                    </button>
                </div>
            </div>
            <div>
                <LogoFooter />
            </div>
        </div>
    );
};

export default AccountLocked;