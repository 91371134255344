import React from 'react';
import question1 from '../question1.png';
import question2 from '../question2.png';
import question3 from '../question3.png';

const QuestionsImage = ( { number } ) => {

    const questions = [question1, question2, question3];
    const source = questions[number-1];

    return (    
        <img className="questions" src={source} alt={"Questions"} />
    );
};

export default QuestionsImage;