import searchParameters from "./searchParameters";

export function getClientId(){
    let returnUrl = new URLSearchParams(window.location.search).get(searchParameters.returnUrlSearchParameter);
    if(returnUrl){
        let url = new URL(returnUrl);
        let clientId = new URLSearchParams(url.search).get('client_id');
        return clientId;
    }
}









