import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { registerServiceWorker, unregister } from './registerServiceWorker';
import axios from 'axios';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// Axios defaults
axios.defaults.baseURL = baseUrl;
// axios.defaults.xsrfCookieName = 'X-CSRF-TOKEN';
axios.defaults.withCredentials = true;

// xsrf
const XSRF_TOKEN_KEY = "xsrfToken";
const XSRF_TOKEN_NAME_KEY = "xsrfTokenName";

function setToken({ token, tokenName }) {
    window.sessionStorage.setItem(XSRF_TOKEN_KEY, token);
    window.sessionStorage.setItem(XSRF_TOKEN_NAME_KEY, tokenName);
    axios.defaults.headers.common[tokenName] = token;
}

function initializeXsrfToken() {
    let token = window.sessionStorage.getItem(XSRF_TOKEN_KEY);
    let tokenName = window.sessionStorage.getItem(XSRF_TOKEN_NAME_KEY);

    if (!token || !tokenName) {
        axios.get("/api/xsrfToken")
            .then(r => setToken(r.data))
            .catch(r => console.log(`Could not fetch XSRF token`));
    } else {
        setToken({ token: token, tokenName: tokenName });
    }
}
function AddFimListener() {
    window.addEventListener("message", function (event) {
        if (window != window.top && (window.location.href.includes(event.origin))) {
            window.location = event.data;
        }
        return;
    });
}

AddFimListener();

initializeXsrfToken();

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

unregister();

