const stepPages = {
    UsernameEntry: 1,
    ForgotUsername: 2,
    ForgotUsernameReminderSent: 3,
    PasswordEntry: 4,
    ForgotPassword: 5,
    ResetPasswordViaSecretQuestion1: 6,
    ResetPasswordViaSecretQuestion2: 7,
    ResetPasswordViaSecretQuestion3: 8,
    ResetPasswordViaEmail: 9,
    ResetPasswordViaEmailSent: 10,
    ResetPasswordAndConfirm: 11,
    AccountLocked: 12,
    ResetPasswordHasBeenReset: 13,
    SelectClinic: 14,
    ForgotUserNameRetrieval: 15,
    IncorrectAccountConfiguration: 16
};

export default stepPages;
