import React, {useState, useEffect } from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import ErrorContainer from "../controls/ErrorContainer";
import stepPages from "../helpers/steps";
import uris from "../helpers/uris";
import searchParameters from "../helpers/searchParameters";
import axios from "axios";

const ForgotUsernameRetrieval = ({goToStep, isActive}) => {

    const [errorDescription, setErrorDescription] = useState(null);
    const [username, setUsername] = useState("");
    const [clientName, setClientName] = useState("eScreen123");
    
    const returnToClient = (newValue) => {
        axios({
                method: "get",
                url: uris.redirecttoclientapplication,
                params: {
                    clientId : clientName,
                },
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
                var redirectUrl  = res.data.url;
                if(redirectUrl){
                    window.location.href = redirectUrl;
                }
            })
            .catch((error) => {
                setErrorDescription("Unknown Error Occured");
            })
    };
    
    function retrieveUsername(requestGuid) {
        
        let defaultError = "Username could not be retrieved";

        var form = new FormData();
        form.append("guid", requestGuid);
        axios({
            method: "post",
            url: uris.retrieveusername,
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log(res);
                    setErrorDescription("");
                    setUsername(res.data.username)
                    setClientName(res.data.clientName);
                    return;
                }
                return;
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    if (error.response.data) {
                        setErrorDescription(defaultError);
                        return;
                    }
                }
                setErrorDescription(defaultError);
            });
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        var guid = urlParams.get(searchParameters.guidSearchParameter);
        
        if (isActive && guid) {
            retrieveUsername(guid);
            return;
        }
        if (isActive && !guid) {
            goToStep(stepPages.UsernameEntry);
        }
    }, [isActive]);

    return (
        <form id={"rpacForm"}>
            <div className="modal-content">
                <LogoHeader clientName={clientName}/>
                <ErrorContainer errorDescription={errorDescription} />
                <div className="stackLabel">
                    <div>
                        <div className="stackLabelContent">
                            <p>Here is your username:</p>
                            <p className="bold"> {username} </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <button
                            id={"furButtonSubmit"}
                            type="button"
                            className="button full-width"
                            onClick={returnToClient}
                        >
                            Return to Login
                        </button>
                    </div>
                </div>
                <div>
                    <LogoFooter />
                </div>
            </div>
        </form>
    );
};

export default ForgotUsernameRetrieval;
