const uris = {
    resetpassword: "api/resetpassword",
    challengeQuestions: "api/challengeQuestions",
    answerChallenges: "api/answerChallenges",
    verifyusername: "api/verifyuser",
    verifypassword: "api/verifypassword",
    sendusernamereminder: "api/sendusernamereminder",
    sendresetpasswordrequest: "api/sendresetpasswordrequest",
    retrieveusername: "api/retrieveusername",
    userclinics: "api/userclinics",
    settings: "api/settings",
    clientsettings:"api/clientsettings",
    redirecttoclientapplication: "api/redirecttoclientapplication"
};
export default uris;