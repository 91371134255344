import React, { createRef, useState, useEffect } from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import axios from "axios";
import FormData from "form-data";
import stepPages from "../helpers/steps";
import uris from "../helpers/uris";
import ErrorContainer from "../controls/ErrorContainer";
import UsernameControl from "./UsernameControl";
import BackButton from "../controls/BackButton";

const PasswordEntry = (props) => {
    let passwordInput = createRef();
    let returnUrlValue = props.returnUrl;
    let defaultErrorMsg = "An Unknown Error Occured";
    const [errorDescription, setErrorDescription] = useState(null);
    const [isPasswordRecoverySupported, setIsPasswordRecoverySupported] = useState(false);

    const submit = (event) => {
        event.preventDefault();
        const inputText = passwordInput.current.value;
        if (inputText.trim() == "") {
            setErrorDescription("Password Required");
            return;
        }
        if (props.username && inputText.trim()) {
            setErrorDescription(null);
            verifyUserAndPassword(inputText);
        }
    };

    const gotoForgotPasswordOptions = () => {
        props.goToStep(stepPages.ForgotPassword);
    };

    const goToResetPassword = () => {
        props.goToStep(stepPages.ResetPasswordAndConfirm);
    };

    const verifyUserAndPassword = (newValue) => {
        var form = new FormData();

        form.append('username', props.username);
        form.append('password', newValue);
        form.append('returnUrl', returnUrlValue);
        axios({
            method: "post",
            url: uris.verifypassword,
            data: form,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((res) => {
                const eScreenRedirectURI = res.data.redirectUrl;
                if (res.data.resetPasswordToken && res.data.resetPasswordReason) {
                    props.setResetPasswordReason(res.data.resetPasswordReason);
                    props.setResetPasswordToken(res.data.resetPasswordToken);
                    goToResetPassword();
                    return;
                }
                if (eScreenRedirectURI) {
                    window.location.href = eScreenRedirectURI;
                    return;
                }
            })
            .catch((error) => {
                if (error.response) {
                    setErrorDescription(error.response.data);
                    return;
                }
                setErrorDescription(defaultErrorMsg);
            });
    };

    useEffect(() => {
        if (props.isActive) {
            setErrorDescription(null);
            props.setResetPasswordReason(null);
            props.setResetPasswordToken(null);
            passwordInput.current.value = null;
            passwordInput.current.focus();
        }

        if(props.settings.featuresSupported.ForgotPasswordChallengeQuestionsRecovery
             || props.settings.featuresSupported.ForgotPasswordEmailRecovery){
            setIsPasswordRecoverySupported(true);
        }
    },[props.username, props.isActive, props.settings]);

    return (
        <form id="passwordEntryForm" onSubmit={e => submit(e)}>
            <div className="modal-content">

                <BackButton myId={"peBackButton"} step={stepPages.UsernameEntry} goToStep={props.goToStep} />
                <LogoHeader clientName={props.settings.clientName} />
                <div>
                    <UsernameControl username={props.username} />
                </div>

                <ErrorContainer errorDescription={errorDescription} />

                <div>
                    <div className="stackLabel">
                        <div className="stackLabelContent">
                            <div>
                                <label>Password</label>
                            </div>
                            <input
                                id={"pePassword"}
                                type="password"
                                className="form-control"
                                ref={passwordInput}
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <button id={"peButtonLogin"} type="submit" className="button full-width" onClick={(e) => submit(e)} >Login</button>
                </div>
                {isPasswordRecoverySupported &&
                    <div>
                        <div onClick={gotoForgotPasswordOptions} className="smalllink" >
                            Forgot Password?
                        </div>
                    </div>
                }
                <div>
                    <LogoFooter />
                </div>

            </div>
        </form>
    );
};

export default PasswordEntry;
