import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const UsernameControl = ( props ) => {
    return (
        <div>
            <div className="modal-child">
                <p><FontAwesomeIcon icon={ faUserCircle } width='10' height='10' className={ "padRight" } />{props.username}</p>
            </div>
        </div>
    );
};

export default UsernameControl;
