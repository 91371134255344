import React, { useState, useEffect, useRef } from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import ErrorContainer from "../controls/ErrorContainer";
import getSettings from "../helpers/getSettings";


const IncorrectAccountConfiguration = props => {

    const [settings, setSettings] = useState(null);
    const [error, setError] = useState(null);

    /**
     * Since this page is navigated to
     * from a Controller, we can't rely on the
     * LoginWizard to populate the props w/ the
     * settings.
     * 
     * Hence, we're using a useEffect when the page loads
     * to retrieve the settings for this page.
     */
    useEffect(() => {
        getSettings('eScreen123', setSettings, setError, 'Unable to retrieve settings!');
    },[]);

    return (
        <form id={"rpacForm"}>
            <div className="modal-content">
                <LogoHeader clientName="eScreen123" />
                <ErrorContainer errorDescription={error}/>
                <div className="stackLabel">
                    <div>
                        <div className="stackLabelContent">
                            <p className="bold">Incorrect Account Configuration</p>
                            <p>Your account is not configured correctly.
                                <br />
                                Please call customer service at:</p>
                            <p className="informational-text">{settings?.clientServicesPhoneNumber}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        {/*Take the user back to the base login page */}
                        <button id={"furButtonSubmit"} type="button" className="button full-width" onClick={() => window.location.href = settings?.identityServerLoginUrl}>
                            Return to Login
                        </button>
                    </div>
                </div>
                <div>
                    <LogoFooter />
                </div>
            </div>
        </form>
    );
};

export default IncorrectAccountConfiguration;