import React from 'react';
import questions from '../forgot-password-questionmarks.svg';

const ResetQuestionsImage = () => {

    return (    
        <div className="questionIcon">
            <img src={questions} alt={"Reset questions"} />
        </div>
    );
};

export default ResetQuestionsImage;